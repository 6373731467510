import React from 'react';
import './Header.scss';
import Logo from '../../../src/assets/logo2.png';
import LogIn from '../../../src/assets/header_profile.png';
import Settings from '../../../src/assets/gear.png';
import Menu from '../../assets/menu.png';
import NavLkw from '../../assets/nav_lkw.png';
import HeaderVideo from '../../assets/new/ezgif-3-143715d702.mp4';
import { getContent } from '../../cms';
import LogoVideo from '../../assets/new/logo.mp4';

export class Header extends React.Component {
  state = {
    showHamburgerMenu: false,
    showCategories: false,
    cmsContent: null
  };

  async componentDidMount() {
    const content = await getContent("/service-sub-pages");
    this.setState({ cmsContent: content });
  }

  toggleMenu() {
    this.setState({
      showHamburgerMenu: !this.state.showHamburgerMenu
    })
  }
  toggleCategories() {
    this.setState({
      showCategories: !this.state.showCategories
    });
  }

  render() {
    return (
      < div >
        <nav className={this.state.showHamburgerMenu ? "mobileMenu" : ""} >
          <div className="nav-top-bar" >
            <div className="header-icon-container">
              <a href="/login" title="ZAC intern" >
                <img src={LogIn} alt=""/>
              </a>
              <a href="https://backend.zacgmbh.de/cms/admin/auth/login" title="ZAC CMS">
                <img src={Settings} alt="" />
              </a>
            </div>
          </div>
          <div className="nav-bottom-bar" >
            <div className="logo-and-categories" >
              <a href="/" className="logo" >
                {/* <img src={Logo} alt="Logo" id="zac-logo" /> */}
                <video src={LogoVideo} muted autoPlay 
                  style={{width: '120px'}}
                ></video>
              </a>
              <div className="categories-container" >
                <div className="category-button"
                  onClick={
                    () => this.toggleCategories()} >
                  <img src={Menu} alt="" /> <p> Kategorien </p>  </div>
                <div className={this.state.showCategories ? "categories-extension" : "categories-extension hide-categories"}>
                  {
                    this.state?.cmsContent?.map(service => (
                      <a className="category" key={service.Title}
                         > {service.Title}
                      </a>
                    ))
                  }
                </div>

              </div>
            </div>
            <div className="elements-container" >
              <div className="elements" >
                <a href="/" > Home </a>
                <a href="/unser-service" > Unser Service </a>
                <a href="/unternehmen" > Unternehmen </a>
                <a href="/anfrageformular" > Kontakt </a>
                <a href="/jobs" > Jobs </a>

                <div className="nav-heading" >
                  {/* <h1 id="heading">
                    ZAC Service und Dienstleistungs GmbH
                  </h1> */}
                  <video src={HeaderVideo} muted autoPlay></video>
                  
                </div>
              </div>
            </div>
            <div className="mobile-elements-container">
              <a href="/" > Home </a>
              <a href="/unser-service" > Unser Service </a>
              <a href="/unternehmen" > Unternehmen </a>
              <a href="/anfrageformular" > Kontakt </a>
              <a href="/jobs" > Jobs </a>

              <strong>Kategorieren</strong>
              {
                this.state?.cmsContent?.map(service => (
                  <a className="category" key={service.Title}> 
                    {service.Title}
                  </a>
                ))
              }
            </div>

            <div className="hamburger"
              onClick={
                () => this.toggleMenu()} >
              <span > </span>
              <span > </span>
              <span > </span>
            </div>
          </div>

        </nav>
      </div>
    )
  }
}

export default Header