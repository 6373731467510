import React from 'react'

export const Datenschutz = () => {
    return (
        <div className="law-container">
            <h1>Datenschutzerklärung für die Webseite</h1>

           

    <h1>Datenschutzerklärung</h1>
    
    <h2>1. Einleitung</h2>
    <p>Mit den folgenden Informationen möchten wir Ihnen als "betroffener Person" einen Überblick über die Verarbeitung Ihrer personenbezogenen Daten durch uns und Ihre Rechte aus den Datenschutzgesetzen geben.</p>
    <p>Eine Nutzung unserer Internetseiten ist grundsätzlich ohne Eingabe personenbezogener Daten möglich. Sofern Sie besondere Services unseres Unternehmens über unsere Internetseite in Anspruch nehmen möchten, könnte jedoch eine Verarbeitung personenbezogener Daten erforderlich werden. Ist die Verarbeitung personenbezogener Daten erforderlich und besteht für eine solche Verarbeitung keine gesetzliche Grundlage, holen wir generell eine Einwilligung von Ihnen ein.</p>
    
    <h2>2. Verantwortlicher</h2>
    <p>ZAC Service und Dienstleistungs GmbH<br/>
       An den Freiheitswiesen 2-4<br/>
       13597 Berlin</p>
    <p>Telefon: +493033607049<br/>
       E-Mail: dispo-berlin@zac.gmbh</p>
    
    <h2>3. Datenschutzbeauftragte</h2>
    <p>Externe Datenschutzbeauftragte<br/>
       c/o TÜV SÜD Akademie GmbH<br/>
       Westendstraße 160<br/>
       80339 München<br/>
       E-Mail: datenschutz@zac.gmbh</p>
    
    <h2>4. Verarbeitung Ihrer personenbezogenen Daten</h2>
    <h3>4.1 Daten, die Sie uns mitteilen</h3>
    <p>In der Regel können Sie unsere Webseite nutzen, ohne dass Sie uns persönliche Informationen direkt mitteilen. Für einige Dienste werden wir von Ihnen persönliche Informationen erfragen, um den jeweiligen Dienst schnell und nutzerfreundlich abwickeln zu können.</p>
    
    <h3>4.2 Daten, die wir durch Ihre Nutzung unserer Dienste erhalten</h3>
    <p>Einige Daten fallen automatisch und aus technischen Gründen bereits dann an, wenn Sie unsere Webseite besuchen.</p>
    <ul>
        <li>Verwendeter Browser</li>
        <li>Verwendetes Betriebssystem</li>
        <li>Quelle/Verweis, von welchem der auf die Seite gelangte</li>
        <li>Datum und Uhrzeit des Zugriffes</li>
        <li>Verwendete IP-Adresse</li>
        <li>Internet-Service-Provider</li>
    </ul>
    
    <h2>5. Verarbeitungen durch Cookies</h2>
    <p>Es werden keine Cookies verwendet.</p>
    
    <h2>6. Weitere Empfänger Ihrer personenbezogenen Daten</h2>
    <p>Ihre personenbezogenen Daten übermitteln wir nur dann an weitere Empfänger, sofern dies erforderlich ist.</p>
    
    <h2>7. Datenübermittlung in Drittländer</h2>
    <p>In einigen Fällen kann eine Übermittlung personenbezogener Daten in ein Drittland erforderlich sein.</p>
    
    <h2>8. Automatisierte Entscheidungen einschließlich Profiling</h2>
    <p>Automatisierte Entscheidungsfindungen einschließlich Profiling finden nicht statt.</p>
    
    <h2>9. Technik</h2>
    <h3>9.1 SSL/TLS-Verschlüsselung</h3>
    <p>Diese Seite nutzt eine SSL- bzw. TLS-Verschlüsselung.</p>
    
    <h3>9.2 Hosting durch Strato</h3>
    <p>Unsere Webseite wird bei der Strato AG gehostet.</p>
    
    <h2>10. Inhalte unserer Internetseite</h2>
    <h3>10.1 Kontaktaufnahme / Kontaktformular</h3>
    <p>Im Rahmen der Kontaktaufnahme werden personenbezogene Daten erhoben.</p>
    
    <h3>10.2 Bewerbungsmanagement</h3>
    <p>Wir erheben und verarbeiten die personenbezogenen Daten von Bewerbern zum Zwecke der Abwicklung des Bewerbungsverfahrens.</p>
    
    <h2>11. Ihre Rechte als betroffene Person</h2>
    <h3>11.1 Recht auf Auskunft gemäß Art. 15 DS-GVO</h3>
    <p>Sie haben das Recht, vom Verantwortlichen Auskunft über die zu Ihrer Person gespeicherten personenbezogenen Daten zu verlangen.</p>
    
    <h3>11.2 Recht auf Berichtigung gemäß Art. 16 DS-GVO</h3>
    <p>Sie haben das Recht, vom Verantwortlichen unverzüglich die Berichtigung Ihrer personenbezogenen Daten zu verlangen.</p>
    
    <h3>11.3 Recht auf Löschung gemäß Art. 17 DS-GVO</h3>
    <p>Sie haben das Recht, vom Verantwortlichen die unverzügliche Löschung Ihrer personenbezogenen Daten zu verlangen.</p>
    
    <h3>11.4 Recht auf Einschränkung der Verarbeitung gemäß Art. 18 DS-GVO</h3>
    <p>Sie haben das Recht, vom Verantwortlichen die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.</p>
    
    <h3>11.5 Recht auf Datenübertragbarkeit gemäß Art. 20 DS-GVO</h3>
    <p>Sie haben das Recht, Ihre personenbezogenen Daten in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten.</p>
    
    <h3>11.6 Recht auf Widerruf gemäß Art. 7 Abs. 3 DS-GVO</h3>
    <p>Sie haben das Recht, Ihre Einwilligung zur Verarbeitung Ihrer personenbezogenen Daten jederzeit zu widerrufen.</p>
    
    <h3>11.7 Recht auf Widerspruch gemäß Art. 21 Abs. 1 DS-GVO</h3>
    <p>Sie haben das Recht, einer Verarbeitung Ihrer personenbezogenen Daten jederzeit zu widersprechen.</p>
    
    <h3>11.8 Recht auf Beschwerde bei einer Aufsichtsbehörde gemäß Art. 77 DS-GVO</h3>
    <p>Sie haben das Recht, sich bei einer Aufsichtsbehörde zu beschweren.</p>
    
    <h2>12. Dauer der Speicherung von personenbezogenen Daten</h2>
    <p>Wir verarbeiten und speichern Ihre personenbezogenen Daten nur für den Zeitraum, der zur Erreichung des Speicherungszwecks erforderlich ist.</p>


        </div>
    )
}

export default Datenschutz
